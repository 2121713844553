<template>
	<div class="user-page__wrapper">
		<div class="user-page">
			<UserMenu />
			<div class="user-page__content">
				<h2>Chat Messages</h2>
				<div v-if="chatLogged" ref="chatWrapper" class="user-page__chat-wrapper">
					<CometChatUI :user="user" />
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import { CometChat } from '@cometchat-pro/chat';
	import { CometChatUI } from '../cc3';

	import $http from '../utils/http.js';

	import UserMenu from '../components/UserMenu';
	import Vue from 'vue';
	import session from '../utils/session';
	import ProfileVisibilityModal from '../components/ProfileVisibilityModal';

	export default {
		name: 'Chat',
		components: {
			CometChatUI,
			UserMenu,
		},
		beforeRouteEnter(to, from, next) {
			Vue.prototype.$Progress.finish();
			if (session.state.user.public_profile === true) {
				next();
			} else {
				if (!from || !from.name) {
					next({ name: 'EditProfile' });
				} else {
					Vue.prototype.$modal.show(
						ProfileVisibilityModal,
						{},
						{
							classes: 'login-modal',
							width: '90%',
							maxWidth: 600,
							height: 'auto',
							adaptive: true,
						},
						{
							'before-close': ({ params }) => {
								if (params === true) {
									next({ name: 'EditProfile' });
								} else {
									next(false);
								}
							},
						}
					);
				}
			}
		},
		beforeRouteUpdate(to, from, next) {
			Vue.prototype.$Progress.finish();
			if (session.state.user.public_profile === true) {
				next();
			} else {
				if (!from || !from.name) {
					next({ name: 'EditProfile' });
				} else {
					Vue.prototype.$modal.show(
						ProfileVisibilityModal,
						{},
						{
							classes: 'login-modal',
							width: '90%',
							maxWidth: 600,
							height: 'auto',
							adaptive: true,
						},
						{
							'before-close': ({ params }) => {
								if (params === true) {
									next({ name: 'EditProfile' });
								} else {
									next(false);
								}
							},
						}
					);
				}
			}
		},
		data() {
			return {
				chatLogged: false,
			};
		},
		computed: {
			user() {
				return this.$route.params.user || parseInt(this.$route.query.user);
			},
		},
		beforeMount() {
			CometChat.getLoggedInUser().then(
				() => {
					this.chatLogged = true;
				},
				() => {
					$http.get('/chat-auth/').then(({ data }) => {
						CometChat.login(data.auth).then(
							User => {
								this.chatLogged = true;
								//console.log('Login Successful:', {User});
							},
							error => {
								//console.log('Login failed with exception:', {error});
								// User login failed, check error and take appropriate action.
							}
						);
					});
				}
			);
		},
		mounted() {
			window.addEventListener('resize', this.onResize);
			window.addEventListener('scroll', this.onResize);
			this.onLoad();
		},
		beforeDestroy() {
			window.removeEventListener('resize', this.onResize);
			window.removeEventListener('scroll', this.onResize);
		},
		methods: {
			changeTab(tab) {
				this.tab = tab;
				this.imageView = null;
				this.viewDetailScreen = false;
				this.viewThreadMessage = false;
			},
			onResize() {
				let winHeight = window.innerHeight;
				if (this.$refs.chatWrapper) {
					let el = this.$refs.chatWrapper.getBoundingClientRect();
					if (el.top > 0) {
						this.$refs.chatWrapper.style.minHeight = `${winHeight - el.top - 60}px`;
						this.$refs.chatWrapper.style.maxHeight = `${winHeight - el.top - 8}px`;
					}
				}
			},
			onLoad() {
				window.setTimeout(() => {
					this.onResize();
				}, 1000);
			},
		},
	};
</script>
